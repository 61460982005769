var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lesson-allocations-wrapper"},[_c('v-card',{staticClass:"lesson-allocations-table-wrapper"},[_c('v-card-title',{staticClass:"lesson-allocations-table-title"},[_c('strong',{staticStyle:{"font-size":"3rem","width":"100%"}},[_vm._v("Lesson Allocations")])]),_c('br'),_c('v-card-text',{staticClass:"no-padding"},[_c('v-row',[_c('v-col',{staticClass:"no-padding toolbar-col",attrs:{"cols":"12","lg":"3","md":"4","sm":"12"}},[_c('v-btn',{staticClass:"create-allocation-button margin-12-top",attrs:{"dark":"","color":"primary"},on:{"click":_vm.openCreateAllocation}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Allocate Lessons ")],1)],1),_c('v-col',{staticClass:"no-padding toolbar-col",attrs:{"cols":"12","lg":"2","md":"4","sm":"12"}},[_c('v-btn-toggle',{staticClass:"lesson-allocation-filter-toggle margin-12-top",attrs:{"dense":"","mandatory":""},on:{"change":_vm.getLessons},model:{value:(_vm.showPast),callback:function ($$v) {_vm.showPast=$$v},expression:"showPast"}},[_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"active-class":"primary-button","color":"#f5f5f5","value":true}},[_vm._v(" Past ")]),_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"active-class":"primary-button","color":"#f5f5f5","value":false}},[_vm._v(" Current ")])],1)],1),_c('v-col',{staticClass:"no-padding toolbar-col",attrs:{"cols":"12","lg":"3","md":"4","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-button",attrs:{"color":_vm.filterIconColor()}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filter by status ")],1)]}}])},[_c('div',{staticClass:"status-filter-dropdown"},_vm._l((_vm.statusList),function(status){return _c('v-list',{key:status.id,staticClass:"no-vertical-padding"},[_c('v-list-item',{class:_vm.filterDropDownClass(status.active),on:{"click":function($event){return _vm.handleStatusSelection(status, false)}}},[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"click":function($event){return _vm.handleStatusSelection(status, true)}},model:{value:(status.active),callback:function ($$v) {_vm.$set(status, "active", $$v)},expression:"status.active"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(status.text))])],1)],1)],1)}),1)])],1),_c('v-col',{staticClass:"no-padding toolbar-col",attrs:{"cols":"12","lg":"4","md":"12","sm":"12"}},[_c('v-text-field',{staticStyle:{"margin-right":"50px","margin-left":"20px"},attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":"","clearable":"","color":"#1f4685"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lessonAllocationsView,"item-key":"id","search":_vm.search,"disable-pagination":"","fixed-header":"","hide-default-footer":"","item-class":_vm.itemRowBackground,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.fullTitle",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"lesson-btn",attrs:{"dark":"","rounded":"","color":"#1f4685","loading":item.loading},on:{"click":function($event){return _vm.openAllocation(item)}}},[_vm._v(" "+_vm._s(item.fullTitle)+" ")])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [(_vm.isDue(item) < 0 && item.state === 'Not Submitted')?_c('p',{staticClass:"no-margin"},[_vm._v("Overdue by "+_vm._s(Math.abs(_vm.isDue(item)))+" day(s)")]):_c('p',{staticClass:"no-margin"},[_vm._v(_vm._s(item.dueDate))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.state),"dark":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteAllocation(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('EditAllocation',{ref:"editAllocationDialog",on:{"updateAllocation":_vm.updateAllocation}}),_c('CreateLessonAllocation',{ref:"createDialog",on:{"created":function($event){_vm.getLessons(); _vm.displaySnackbar()}}}),_c('v-dialog',{attrs:{"max-width":"380"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s("Are you sure you want to delete this allocation?")+" ")]),_c('v-card-text',[_vm._v(" All information about the allocation will be deleted ")]),_c('v-card-actions',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center"}},[_c('v-btn',{staticClass:"ma-1",staticStyle:{"color":"grey"},attrs:{"plain":""},on:{"click":_vm.cancelDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ma-1",staticStyle:{"color":"red"},attrs:{"plain":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Delete ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"value":_vm.showSnackbar,"absolute":"","top":"","color":"#3c882f"}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Allocation created successfully ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }